import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import DownloadCsv from 'vue-json-csv'

// Load fonts
loadFonts()

// Create pinia store
const pinia = createPinia()

// Create app
const app = createApp(App)

// Register global components
app.component('download-csv', DownloadCsv)

// Use plugins
app.use(pinia)
app.use(router)
app.use(vuetify)

// Mount app
app.mount('#app')