<template>
  <v-app>
    <!-- Navigation Drawer -->
    <v-navigation-drawer v-model="drawer">
      <v-list>
        <v-list-item title="SafeTelecom Dashboard" class="text-h6"></v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list density="compact" nav>
        <v-list-item to="/" title="Dashboard" prepend-icon="mdi-view-dashboard"></v-list-item>
        <v-list-item to="/clients" title="Clients" prepend-icon="mdi-account-multiple"></v-list-item>
        <v-list-item to="/tenants" title="Tenants" prepend-icon="mdi-domain"></v-list-item>
        <v-list-item to="/ota" title="OTA Management" prepend-icon="mdi-update"></v-list-item>
      </v-list>
    </v-navigation-drawer>

    <!-- App Bar -->
    <v-app-bar>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>Dashboard</v-toolbar-title>
      <v-spacer></v-spacer>

      <v-menu location="bottom end">
        <template v-slot:activator="{ props }">
          <v-btn icon v-bind="props">
            <v-icon>mdi-account-circle</v-icon>
          </v-btn>
        </template>
        <profile-menu />
      </v-menu>
    </v-app-bar>

    <!-- Main Content -->
    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { defineComponent, ref } from 'vue'
import ProfileMenu from './components/ProfileMenu.vue'

export default defineComponent({
  name: 'App',
  components: {
    ProfileMenu
  },
  setup() {
    const drawer = ref(true)

    return {
      drawer
    }
  }
})
</script>